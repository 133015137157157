.button {
    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to right, white 50%, #00de00 50%) left !important;
    background-size: 201% !important;
    
}

.button:hover {
    background-position: right !important;
    color: black;
    transition: 0.5s ease-out; 
}

.button:focus {
    box-shadow: none !important;
}

.cancel-button {
    background-color: #0a0a0a !important;
    color: white !important;
}

.cancel-button:hover {
background-color: rgb(43, 43, 43) !important;
}

.button2 {
    background-color: #00ed00 !important;
}

.button2:hover {
    background-color: #03b403 !important;
}

.button2:focus {
    box-shadow: none !important;
}
